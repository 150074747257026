import { useEffect,useState } from 'react';
import '../styles/assessmentcomplete.css';
import successIcon from '../images/success.png'
import errorIcon from '../images/error.png'
import IAssessmentCompleteProps from '../interfaces/props/IAssessmentCompleteProps';
import { ValidateRedirectUrlConfig } from '../services/validateRedirectUrlConfig';
import { IRedirectUrlConfig } from '../interfaces/IRedirectUrlConfig';

export default function AssessmentCompletePage({status, t, config}: IAssessmentCompleteProps) {
    const internalSuccessTemplate = {
        graphic: successIcon, 
        header: t("TEXT_THANK_YOU_FOR_COMPLETING_THE_COGSTATE_COGNITIVE_TEST"), 
        text: t("TEXT_YOU_MAY_NOW_CLOSE_THIS_PAGE")
    }
    const internalErrorTemplate = {
        graphic: errorIcon, 
        header: t("ERROR_WITH_THE_COGSTATE_COGNITIVE_TEST"), 
        text: t("ERROR_HAS_OCCURED_CONTACT_ADMINISTRATOR")
    }

    let redirectUrlConfig:IRedirectUrlConfig = config.redirectUrlConfig; 
    const [template,setTemplate] = useState(status==="error"?internalErrorTemplate:internalSuccessTemplate);
    const accessCodeKey = "accessCode"
    const localeCodeKey = "localeCode"

    const convertConfigToTemplate = () =>{
        if(!ValidateRedirectUrlConfig(redirectUrlConfig)){
            alert(t("ERROR_CONFIG_INVALID"))
            return;
        }
        const redirectUrlType = redirectUrlConfig.redirectUrlType

        if(redirectUrlType === "InternalCustomMessage"){
            if(status==="error"){
                setTemplate({
                    graphic: errorIcon,
                    header: redirectUrlConfig.customErrorTitle,
                    text: redirectUrlConfig.customErrorText
                })
            }else{
                setTemplate({
                    graphic: successIcon,
                    header: redirectUrlConfig.customSuccessTitle,
                    text: redirectUrlConfig.customSuccessText
                })
            }
       }
    }

    const setTextDangerouslyIfInternalCustomMessage = (text:string,className:string) =>{
        if(config.redirectUrlConfig.redirectUrlType === "InternalCustomMessage"){
            return <div className={className} dangerouslySetInnerHTML={{__html:text}}/> 
        }else{
            return text
        }
    }
    const deleteAccessCodeIfError=()=>{
        if(status==="error"){
            localStorage.removeItem(accessCodeKey)
            localStorage.removeItem(localeCodeKey)
        }
    }

    useEffect(() =>{
        convertConfigToTemplate()
    },[redirectUrlConfig])
    
    useEffect(() => {
        document.body.className = 'landingpage-background';
        deleteAccessCodeIfError()
    }, []);

    return <div className="landingpage-container">
                <img className="landingpage-graphic" src={template.graphic} alt={template.header}/>
                    <div className="landingpage-text-container">
                        {setTextDangerouslyIfInternalCustomMessage(template.header,"landingpage-header")}
                        {setTextDangerouslyIfInternalCustomMessage(template.text,"landingpage-text")} 
                    </div>
            </div>
}