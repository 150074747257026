import IBasei18nProps from "../interfaces/props/base/IBasei18nProps";
import IBaseConfigWi18nProps from "../interfaces/props/base/IBaseConfigWi18nProps";

export default function Footer({t, config}: IBaseConfigWi18nProps) {

    const setMiddleContent = () => {
        const defaultMiddle = <><div className='footer--dark-text-header' >{t("TEXT_WHAT_TO_EXPECT")}</div>
            <div className='footer--dark-text'>{t("TEXT_MADE_UP_OF_MULTIPLE_SECTIONS")}</div>
            <div className='footer--dark-text'>{t("TEXT_GIVE_IT_YOUR_BEST_EFFORT")}</div></>
        return setCustomOrDefaultContent(defaultMiddle, config.landingPageConfig.landingPageMiddleCustomEnabled, config.landingPageConfig.landingPageMiddleCustomContent)
    }

    const setBottomContent = () => {
        const defaultBottom = <>
            <div className='footer--light-text-header'>{t("TEXT_HOW_TO_PREPARE")}</div>
            <ol className='footer--list'>
                <li className='footer--list-item'>
                    <div className='footer--list-header'>{t("TEXT_LOCATION_UPPER_CASE")}</div>
                    <div className='footer--list-text'>{t("TEXT_FIND_QUIET_ROOM")}</div>
                </li>
                <li className='footer--list-item'>
                    <div className='footer--list-header'>{t("TEXT_DEVICE_UPPER_CASE")}</div>
                    <div className='footer--list-text'>{t("TEXT_USE_TABLET_OR_COMPUTER")}</div>
                </li>
                <li className='footer--list-item'>
                    <div className='footer--list-header'>{t("TEXT_VISION_OR_HEARING_AIDS_UPPER_CASE")}</div>
                    <div className='footer--list-text'>{t("TEXT_IF_EYEGLASSES_OR_HEARING_AIDS_PUT_THEM_ON")}</div>
                </li>
            </ol></>
        return setCustomOrDefaultContent(defaultBottom, config.landingPageConfig.landingPageBottomCustomEnabled, config.landingPageConfig.landingPageBottomCustomContent)
    }

    const setCustomOrDefaultContent = (defaultContent: JSX.Element, replacementEnabled: boolean, replacementContent: string | undefined) => {
        if(replacementEnabled && replacementContent) {
            return <div dangerouslySetInnerHTML={{__html:replacementContent}}/>;
        } else {
            return defaultContent;
        }
    }

    return (
        <div className='footer'>
            <section  className='footer--expectation-panel'>
                {setMiddleContent()}
            </section>
            <section className='footer--preparation-panel'>
                {setBottomContent()}
            </section>
        </div>
    );
}
