import IStudy from "./IStudy"
import {ILink} from "./ILink";
import {IFeatureConfigurations} from "./props/IFeatureConfigurations";
import { IRedirectUrlConfig } from "./IRedirectUrlConfig";
import { ILandingPageConfig} from "./ILandingPageConfig";

export interface IConfiguration {
  theme: string;
  refuseInvalidFieldParamLink: boolean;
  localityCode: string;
  localityName: string;
  studies: Array<IStudy> | null;
  privacyPolicy: ILink;
  termsAndConditions: ILink;
  featureConfigurations : IFeatureConfigurations;
  maxAccessCodes: number;
  redirectUrlConfig: IRedirectUrlConfig;
  landingPageConfig: ILandingPageConfig;
}

export const defaultConfiguration = {
  theme: "default",
  refuseInvalidFieldParamLink: false,
  localityCode: "",
  localityName: "",
  studies: null,
  privacyPolicy: {text: "Privacy Policy", url: ""},
  termsAndConditions:{text: "Terms And Conditions", url: ""},
  featureConfigurations: {useDefaultGender: false, defaultGender: "", useDefaultYOB: false, defaultYOB: ""},
  maxAccessCodes: 1000,
  redirectUrlConfig:{
    redirectUrlType: "Internal",
    customSuccessTitle: "Test custom success title-client",
    customErrorTitle: "Test custom error title",
    customSuccessText: "Test custom success text-client",
    customErrorText: "Test custom error text",
    templateSuccessUrl: "",
    templateErrorUrl: ""},
  landingPageConfig: {
    landingPageTitleCustomEnabled: false,
    landingPageMiddleCustomEnabled: false,
    landingPageBottomCustomEnabled: false,
  }
};