import ILoadingProps from "../interfaces/props/ILoadingProps"

const Loading = ({t, descTextKey}: ILoadingProps) => {

  return (
    <div className="menu--background-image">
      <div className='menu--container-full-width'>
        <h3 className='menu--text-header'>{t("TEXT_LOADING")}</h3>
        <p className='menu--text'>{t(descTextKey)}</p>
      </div>
    </div>
  )
}

export default Loading