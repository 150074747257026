export async function ValidateAccessCode(accessCode: string) {
    try {

        const response = await fetch(`${AppSettings.ApiEndpointUrl}/ValidateAccessCode/${accessCode}`);
        if (!response.ok) {
            return false
        }
        return await response.json();
    } catch (err) {
        return false;
    }
}