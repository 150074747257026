import { IAssessmentFormErrorMapProps } from "../interfaces/props/IAssessmentFormErrorServiceProps"
import { IErrorMap } from "../interfaces/IErrorMap"
import { IAssessmentFormErrorMap } from "../interfaces/IAssessmentFormErrorMap"

//Error notifs
const errorFieldRequiredTextKey = "ERROR_FIELD_REQUIRED"
const errorYearReqs4CharsTextKey = "ERROR_YEAR_MUST_HAVE_4_CHARACTERS"
const errorYearDigitsOnlyTextKey = "ERROR_YEAR_CAN_ONLY_INCLUDE_DIGITS"

function formFieldNotEmpty(value:string):boolean{
  return value !== "";
}

function yearLengthValidation(value:string):boolean{
  return value.length===4;
}

function yearDigitValidation(value:string):boolean{
  return /^\d+$/.test(value);
}

export const AssessmentFormErrorService = (
  {
    searchParams: sp,
    refuseInvalidFieldParamLink, 
    accessCodeSettings, 
    yobSettings, 
    genderSettings, 
    studySettings, 
    sessionSettings,
    localeSettings,
    paramHasMatch
  }: IAssessmentFormErrorMapProps
) => {
  
  const errorMap: IAssessmentFormErrorMap = {
    accessCode: {
      ...accessCodeSettings,
      queryParam: sp.accessCode,
      validations:[
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey 
        }
      ]
    },
    yob: {
      ...yobSettings,
      queryParam: sp.yob,
      validations:[
        {
          validation : yearLengthValidation,
          failValue: errorYearReqs4CharsTextKey
        },
        {
          validation : yearDigitValidation,
          failValue: errorYearDigitsOnlyTextKey
        }, 
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey 
        },
      ]
    },
    gender: {
      ...genderSettings,
      queryParam: sp.gender,
      validations:[
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey 
        }
      ]
    },
    study: {
      ...studySettings,
      queryParam: sp.study,
      validations:[
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey 
        }
      ]
    },
    session: {
      ...sessionSettings,
      queryParam: sp.session,
      validations:[
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey 
        }
      ]
    },
    locale:{
      ...localeSettings,
      validations:[
        {
          validation : formFieldNotEmpty,
          failValue : errorFieldRequiredTextKey
        }
      ]
    }
  }

  const validate = (): boolean => {
    let validForm = true;
    let validParams = true;

    for (const fieldErrorMap of Object.entries(errorMap)) {
      const [currKey, currVal] = fieldErrorMap
      const {queryParam} = currVal
      if(queryParam && refuseInvalidFieldParamLink && validParams) {
        validParams = validateAsParam(paramHasMatch[currKey], currVal);
      }
      else {
        validForm = validateAsForm(currVal) && validForm;
      }
    }
    
    return validForm && validParams
  }

  const validateParams = () =>  {
    let valid = true;

    for (const fieldErrorMap of Object.entries(errorMap)) {
      const [currKey, currVal] = fieldErrorMap; 
      const {queryParam} = currVal;
      if(queryParam) {
        if(valid) {
          valid = validateAsParam(currVal, paramHasMatch[currKey]);
        }
        else {
          break;
        }
      }
    }

    return valid;
  }
  
  const validateAsForm = ({value, setError, validations}: IErrorMap): boolean  => {
    let fieldValid = true;

    validations?.forEach(({validation,failValue})=>{
      let validationRes = validation(value);
      if (!validationRes){
        fieldValid=false
        setError(failValue)
        return
      }
    })
    if (fieldValid){
      setError("")
    }

    return fieldValid;
  }
  
  const validateAsParam = ({ value, validations}: IErrorMap, paramHasMatch?: boolean): boolean => {
    let fieldValid = true;
    if (paramHasMatch !== false) {
      validations?.forEach(({validation})=>{
        let validationRes = validation(value);
        if (!validationRes){
          fieldValid = false
          return
        }
      })
    }
    else {
      fieldValid = false;
    }

    return fieldValid
  }

  return {validate, validateParams, validateAsParam, errorMap}
}