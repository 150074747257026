import {IPoliciesProps} from "../interfaces/props/IPoliciesProps";

export default function Policies({termsAccepted, setTermsAccepted, config, t}: IPoliciesProps) {
    const privacyUrl = config.privacyPolicy.url;
    const privacyText = config.privacyPolicy.text;
    const termsUrl = config.termsAndConditions.url
    const termsText = config.termsAndConditions.text;
    const privacyPolicyAndTerms = t('HTML_PRIVACY_POLICY_AND_TERMS', {
        startPrivacyLink: "<a target='_blank' href=" + privacyUrl + ">",
        privacyText: privacyText,
        startTermsLink: "<a target='_blank' href=" + termsUrl + ">",
        termsText: termsText,
        endLink: "</a>",
        interpolation: {escapeValue: false}
    })
    return (
        <div className={"policy--container"}>
            <input className="policy--checkbox" type="checkbox" checked={termsAccepted} onChange={(event) => {
                setTermsAccepted(event.target.checked)
            }}></input>
            <div>
                <p className={"light-text"} dangerouslySetInnerHTML={{
                    __html: privacyPolicyAndTerms
                }}>
                </p>
            </div>
        </div>
    )
}