import { defaultConfiguration } from "../interfaces/IConfiguration"
import {t} from "../i18n/i18n";

export async function GetConfig() {
  try {
    const response = await fetch(`${AppSettings.ApiEndpointUrl}/GetConfiguration`);
    const json = await response.json();
    if (!response.ok) {
      alert(json.message)
    }
    return json;
  } catch (err) {
    alert(t("ERROR_GETTING_CONFIGURATION"));
    return defaultConfiguration;
  }
}