import SubmitButton from "../components/SubmitButton";
import {useEffect, useState} from "react";
import CodesFooter from "../components/codesFooter";
import { MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import AzureADError from "../components/AzureADError";
import StyleWrapper from "../components/StyleWrapper";
import { GenerateAccessCodes } from "../services/generateCodeService";
import IBaseConfigWi18nProps from "../interfaces/props/base/IBaseConfigWi18nProps";
import Loading from "../components/Loading";


export default function GenerateCodesPage({config, t}: IBaseConfigWi18nProps) {

    const accessCodeLimits = {
        min: 50,
        max: config.maxAccessCodes
    }
    const { instance, accounts } = useMsal();
    const [generateAmount, setGenerateAmount] = useState(accessCodeLimits.min);
    const [isFetching,setIsFetching] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [delayActive,setDelayActive] = useState(false);

    const getAccessToken = async (): Promise<string> => {
        const accessTokenRequest = {
            scopes: AppSettings.AzureLoginRequest.scopes,
            account: accounts[0]
        };

        let accessToken: string = "";

        await instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
              accessToken = accessTokenResponse.accessToken
            })
            .catch((error) => {
              if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
              }
            });

        return accessToken;
    }

    const updateGenerateAmount = (value: string) => {
        let newValue = parseInt(value)
        setGenerateAmount(newValue)
    }

    const requestCodes = async () => {
        setIsFetching(true)
        setDelayActive(true)
        setTimeout(function() {if(!isFetching){setDelayActive(false)}},750) 
        const accessToken = await getAccessToken();
        if(accessToken !== "") {
            GenerateAccessCodes(accessToken, generateAmount);
        }
        else {
            alert(t("ERROR_NO_AZURE_ACCESS_TOKEN_FOUND"))
        }
        setIsFetching(false)
    }

    useEffect(()=>{
        if(!delayActive && !isFetching){
            setButtonDisabled(false)
        }else{
            setButtonDisabled(true)
        }
    },[delayActive, isFetching])

    const AzureLoading = () => <Loading t={t} descTextKey="TEXT_AUTHENTICATION_IN_PROGRESS"/>
    
    return (
        <StyleWrapper>
            <MsalAuthenticationTemplate
                authenticationRequest={AppSettings.AzureLoginRequest}
                interactionType={InteractionType.Redirect} 
                errorComponent={AzureADError} 
                loadingComponent={AzureLoading}
            >
                <div className="menu--background-image">
                    <div className='menu--container menu--generate-codes'>
                        { accessCodeLimits.max >= accessCodeLimits.min ?
                            <>
                                <h3 className='menu--text-header'>{t("TEXT_GENERATE_ACCESS_CODES")}</h3>
                                <p className='menu--text'>{t("TEXT_PLEASE_SELECT_NUM_OF_ACCESS_CODES_TO_GENERATE")}</p>
                                <div className='menu--responsive'>
                                    <input type="range"
                                        min={accessCodeLimits.min}
                                        max={accessCodeLimits.max}
                                        step="50"
                                        className="slider"
                                        value={generateAmount}
                                        onChange={(event)=>updateGenerateAmount(event.target.value)}
                                    />
                                </div>
                                <p className='menu--text'>{t("TEXT_ACCESS_CODES_TO_BE_GENERATED")} <b>{generateAmount}</b> </p>
                                <SubmitButton 
                                    className={buttonDisabled?"generate-button--disabled":"generate-button"} 
                            	    buttonTextKey="TEXT_GENERATE_ACCESS_CODES"
                                    action={requestCodes} 
                                    disabled={buttonDisabled}
                                />
                            </> 
                            :
                            <>
                                <h3 className='menu--text-header'>Invalid value set for access code generation limit.</h3>
                                <p className='menu--text'>Please fix deployment configuration.</p>
                            </>
                        }
                    </div>
                </div>
                <CodesFooter/>
            </MsalAuthenticationTemplate>
        </StyleWrapper>
    )
}